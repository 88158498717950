import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2811543174/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`React Native SDK v2 Releases`}</h1>
    <h2>{`2.1.1`}</h2>
    <ul>
      <li parentName="ul">{`Updated consent text for Mastercard cards.`}</li>
    </ul>
    <h2>{`2.1.0`}</h2>
    <ul>
      <li parentName="ul">{`Add card enrollment & verification metrics tracking features to help us improve our services.`}</li>
    </ul>
    <h2>{`2.0.0`}</h2>
    <ul>
      <li parentName="ul">{`We have released all the changes to the SDK from the beta track as a new major stable version.
After months of rigorous testing, we are confident that Transaction Stream SDK is ready to empower your projects so you can create more valuable, engaging experiences for your customers.`}</li>
    </ul>
    <h2>{`2.0.0-beta14`}</h2>
    <ul>
      <li parentName="ul">{`Card scanning is no longer supported. `}</li>
      <li parentName="ul">{`The options `}<inlineCode parentName="li">{`enableCardScanner`}</inlineCode>{` and `}<inlineCode parentName="li">{`shouldAutoScanCard`}</inlineCode>{` were removed.`}</li>
    </ul>
    <h2>{`2.0.0-beta13`}</h2>
    <ul>
      <li parentName="ul">{`Updated the micro-charge descriptor text to the correct one.`}</li>
    </ul>
    <h2>{`2.0.0-beta12`}</h2>
    <ul>
      <li parentName="ul">{`Added the `}<inlineCode parentName="li">{`Fidel.onCardVerificationChoiceSelected`}</inlineCode>{` callback `}<em parentName="li">{`(Experimental feature)`}</em>{` that communicates the cardholder's choice and intention to either verify the card on the spot (because the cardholder has access to the card statement) or to express the intention to delegate card verification to a third-party entity.`}</li>
    </ul>
    <h2>{`2.0.0-beta11`}</h2>
    <ul>
      <li parentName="ul">{`Added the `}<inlineCode parentName="li">{`thirdPartyVerificationChoice`}</inlineCode>{` property to allow cardholders to choose between verifying the card on the spot (as previously available) or indicate that the cardholder does not have access to the card statement and needs to delegate card verification to a third-party entity.`}</li>
      <li parentName="ul">{`Added the `}<inlineCode parentName="li">{`onCardVerificationStarted`}</inlineCode>{` callback which communicates that card verification has started for a card that was enrolled in a Fidel program. This callback provides the `}<em parentName="li">{`consent details`}</em>{` that are created. Use these details to start the card verification.`}</li>
      <li parentName="ul">{`Added the `}<inlineCode parentName="li">{`Fidel.verifyCard`}</inlineCode>{` function to attempt card verification for a previously enrolled card. This function can be used for either the cardholder or a third-party entity (that cannot enroll cards, but can verify cards).`}</li>
      <li parentName="ul">{`Changes in the verification screen text that gives a better description on how the micro-charge will be displayed in the card statement`}</li>
      <li parentName="ul">{`Removes the currency symbol during the input of the verification token (the micro-charge amount).`}</li>
      <li parentName="ul">{`Added Norway to the countries supported by the SDK.`}
        <ul parentName="li">
          <li parentName="ul">{`If you specified the `}<inlineCode parentName="li">{`allowedCountries`}</inlineCode>{` property, remember to add Norway, to have it in the `}<inlineCode parentName="li">{`Country of issue`}</inlineCode>{` list.`}</li>
          <li parentName="ul">{`If you didn't customize the `}<inlineCode parentName="li">{`allowedCountries`}</inlineCode>{` property, Norway will automatically show up in the `}<inlineCode parentName="li">{`Country of issue`}</inlineCode>{` field after you integrate the newest version of the SDK.`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`2.0.0-beta10`}</h2>
    <ul>
      <li parentName="ul">{`Card scanning will now be disabled by default, use  the option `}<inlineCode parentName="li">{`enableCardScanner: true`}</inlineCode>{` to enable card scanning.`}</li>
    </ul>
    <h2>{`2.0.0-beta9`}</h2>
    <ul>
      <li parentName="ul">{`This version displays better text to cardholders explaining that card verification micro-charges will be refunded within 72 hours.`}</li>
    </ul>
    <h2>{`2.0.0-beta8`}</h2>
    <ul>
      <li parentName="ul">{`This version provides a comprehensive error message in case the micro-charge fails. You don't need to make any changes to your code in order to use this version.`}</li>
    </ul>
    <h2>{`2.0.0-beta6`}</h2>
    <p>{`The library now includes all the changes in the Android 2.0.0-beta8 and all the changes in the iOS 2.0.0-beta8 releases.`}</p>
    <h2>{`2.0.0-beta5`}</h2>
    <p>{`The library now includes all the changes in the Android 2.0.0-beta6, Android 2.0.0-beta7 and all the changes in the iOS 2.0.0-beta6, iOS 2.0.0-beta7`}</p>
    <h2>{`2.0.0-beta4`}</h2>
    <p>{`The library now includes all the changes in the Android 2.0.0-beta5 and all the changes in the iOS 2.0.0-beta5`}</p>
    <h2>{`2.0.0-beta3`}</h2>
    <p>{`The library now includes all the changes in the Android 2.0.0-beta4 and all the changes in the iOS 2.0.0-beta4`}</p>
    <h2>{`2.0.0-beta2`}</h2>
    <ol>
      <li parentName="ol">{`The library now includes all the changes in the Android 2.0.0-beta3 and all the changes in the iOS 2.0.0-beta3.`}</li>
      <li parentName="ol">{`For the Android library:`}
        <ol parentName="li">
          <li parentName="ol">{`It does not use `}<inlineCode parentName="li">{`jCenter()`}</inlineCode>{` repository anymore, but `}<inlineCode parentName="li">{`mavenCentral()`}</inlineCode>{`.`}</li>
          <li parentName="ol">{`Updated `}<inlineCode parentName="li">{`targetSdkVersion`}</inlineCode>{` version to `}<inlineCode parentName="li">{`31`}</inlineCode>{`.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`For the iOS library we fixed the `}<inlineCode parentName="li">{`companyName`}</inlineCode>{` parameter and the library compiles fine now.`}</li>
    </ol>
    <h2>{`2.0.0-beta1`}</h2>
    <ul>
      <li parentName="ul">{`Renamed the `}<inlineCode parentName="li">{`apiKey`}</inlineCode>{` property to `}<inlineCode parentName="li">{`sdkKey`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Added the `}<inlineCode parentName="li">{`programType`}</inlineCode>{` property to specify the program type that you'll use in your app.`}</li>
      <li parentName="ul">{`If the `}<inlineCode parentName="li">{`programType`}</inlineCode>{` property is set to `}<inlineCode parentName="li">{`.transactionStream`}</inlineCode>{`, users will be able to start the card verification flow.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      